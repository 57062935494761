/* PartnersRoutingMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { baPartnersStore, userStore } from '@/store';
import { BAPartnerModel } from '@/models';
import { Route } from 'vue-router';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { StringsMixin, RoutingMixin } from '@/mixins';

@Component
export class PartnersRoutingMixin extends Mixins(StringsMixin, RoutingMixin) {
	mounted() {
		baPartnersStore.loadPartners();
	}

	get IsLoadedPartners(): boolean {
		return baPartnersStore.loadedPartners;
	}
	get HasPartners(): boolean {
		if( !this.IsLoadedPartners ) return false;
		return baPartnersStore.numPartners > 0;
	}
	get Partners(): Array<BAPartnerModel> {
		const premiumPartners: Array<string> = ["Best Education"];
		return baPartnersStore.partners.filter(p => !premiumPartners.includes(p.name));
	}
	HasPartner(name: string): boolean {
		const partner = baPartnersStore.partners.find(p => p.name === name);
		return this.IsNotEmpty(partner);
	}

	RouteToPartner(partnerName: string): Partial<Route> {
		if( userStore.IsReady ) return this.getRoute( Routes.AthletePartner, { partnerName } );
		return this.getRoute( Routes.AthletePublicPartner, { partnerName } );
	}
	get RouteToBestEducation(): Partial<Route> {
		if( userStore.IsReady ) return this.getRoute( Routes.AthleteBestEducation );
		return this.getRoute( Routes.AthletePublicBestEducation );
	}
}
