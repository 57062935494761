
import { mdiChevronDoubleRight } from '@mdi/js';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AuthMixin, VuetifyMixin, AppHostnameMixin, BAIconsMixin } from '@/mixins';
import { userStore, notificationStore } from '@/store';
import UserMenu from '@/components/ui/UserMenu.vue';
import BASnackbar from '@/components/ui/BASnackbar.vue';
import BannerNotifications from '@/components/notifications/BannerNotifications.vue';
import { PageState } from '@/models/PageState';
import { userApi } from '@/api/UserApi';
import { NotificationErrorStatus } from '@/../types/enums';

interface Breadcrumb{
	text: string,
	disabled: boolean,
	to: string,
}

@Component({
	components: { BannerNotifications, BASnackbar, UserMenu },
})
export default class Page extends Mixins(AuthMixin, AppHostnameMixin, VuetifyMixin, BAIconsMixin){
	mdiChevronDoubleRight = mdiChevronDoubleRight;

	get showSnackbar(): boolean{
		return notificationStore.showSnackbar;
	}
	set showSnackbar(val: boolean){
		notificationStore.setSnackbarSuccess(val);
	}
	get SnackbarMessage(): string | null{
		if(notificationStore.snackbarSuccess === null) return null;
		return notificationStore.snackbarSuccess.message;
	}
	get SnackbarProps(): Record<string, any>{
		const props: Record<string, any> = {
			timeout: 6000,
		};
		if(notificationStore.snackbarSuccess !== null){
			if(notificationStore.snackbarSuccess.errorStatus === NotificationErrorStatus.Success){
				props.success = true;
			}else if(notificationStore.snackbarSuccess.errorStatus === NotificationErrorStatus.Warning){
				props.warning = true;
			}else if(notificationStore.snackbarSuccess.errorStatus === NotificationErrorStatus.Error){
				props.error = true;
			}
		}
		return props;
	}

	@Prop({ default: false, type: Boolean }) noPadding: boolean;
	@Prop({ default: null }) state: PageState | null;
	@Prop({ default: false, type: Boolean }) usePageLoader: boolean;
	get State(): PageState{
		if(this.state === null) return new PageState("Ready");
		return this.state;
	}
	@Prop({ default: null, type: String }) recipientId: string | null;
	get RecipientId(): string{
		if(this.recipientId !== null) return this.recipientId;
		if(this.AthleteAppActive) return userStore.athleteId;
		if(this.CoachAppActive) return userStore.coachId;
		return null;
	}
	@Prop({ type: Boolean }) loading;
	@Prop({ default: true, type: Boolean }) noHeader: boolean;
	@Prop({ default: false, type: Boolean }) hideBannerNotifications: boolean;
	@Prop({ default: false, type: Boolean }) horizontalScroll: boolean;
	/**
	 * A fluid Page will fill the whole screen width. Not fluid will have a max content width and be left aligned
	 */
	@Prop({ default: true, type: Boolean }) fluid: boolean;
	get BreadCrumbs(){
		const breadcrumbs: Breadcrumb[] = this.$route.meta.breadcrumbs;
		if(breadcrumbs){
			return breadcrumbs.map(b => ({
				...b, to: this.replaceParams(b.to, this.$route.params),
			}));
		}else{
			return [];
		}
	}
	@Prop({ default: false, type: Boolean }) hideBreadcrumbs: boolean;
	showVerificationEmailAlert: boolean = true;
	resentEmail: boolean = false;
	get ShowBreadcrumbs(){
		return !this.hideBreadcrumbs && this.BreadCrumbs.length > 0;
	}

	get ContainerStyles(): Record<string, any>{
		if(this.fluid === false){
			return {
				'max-width': '1176px',
			};
		}
		return {};
	}
	get ContainerClasses(): Record<string, boolean>{
		return {
			'ml-0': this.fluid === false,
			'white': this.$vuetify.breakpoint.smAndDown,
			'horizontal-scroll': this.horizontalScroll,
			'pb-6 px-sm-6 pb-sm-12': !this.noPadding,
			'pa-0 pb-6': this.noPadding,
		};
	}
	get EmailVerified(): boolean{
		if( this.IsAdmin ) return true;
		return userStore.EmailVerified
	}
	async resendEmail(): Promise<void>{
		if (!this.resentEmail){
			try {
				await userApi.resendVerificationEmail()
				this.resentEmail = true;
				this.showVerificationEmailAlert = false;
			} catch (error) {
				notificationStore.pushErrorNotificationMessage("Unable to send verification email. Please try again later");
			}
		}
	}
	/**
	 * Update breadcrum to from /team/:id to /team/123
	 */
	replaceParams(path: string, params: { [param: string]: string }){
		let to: string = path;
		for(const [key,value] of Object.entries(params)){
			path = path.replace(`:${key}`, value);
		}
		return path;
	}

	get ShowBannerNotifications(){
		return !this.hideBannerNotifications;
	}

	closeSuccessEmail(): void{
		this.resentEmail = false;
	}
}
