// AthleteApiMixin.ts

import { Component, Mixins } from 'vue-property-decorator';
import { AppHostnameMixin } from '@/mixins';
import { sportStore } from '@/store';

@Component
export class AthleteApiMixin extends Mixins(AppHostnameMixin) {
	created() {
		this.loadSports();
	}

	loadingSports = true;
	async loadSports(): Promise<void>{
		this.loadingSports = true;
		await sportStore.loadSports();
		this.loadingSports = false;
	}
	get IsAthleteApiReady(): boolean {
		return !this.loadingSports;
	}
}
