import store from "../store";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { PaginatedResponse, QueryOptions } from "@/../types/interfaces";
import { SportModel } from "@/models/sport/SportModel";
import { sportApi } from "@/api/SportApi";

const Mutations = {
	QUERY_SPORTS: 'QUERY_SPORTS',
	QUERY_SPORTS_SUCCESS: 'QUERY_SPORTS_SUCCESS',
	QUERY_SPORTS_FAILURE: 'QUERY_SPORTS_FAILURE',
	FINDBYID_SPORTS: 'FINDBYID_SPORTS',
	FINDBYID_SPORTS_SUCCESS: 'FINDBYID_SPORTS_SUCCESS',
	FINDBYID_SPORTS_FAILURE: 'FINDBYID_SPORTS_FAILURE',
	FINDBYNAME_SPORTS: 'FINDBYNAME_SPORTS',
	FINDBYNAME_SPORTS_SUCCESS: 'FINDBYNAME_SPORTS_SUCCESS',
	FINDBYNAME_SPORTS_FAILURE: 'FINDBYNAME_SPORTS_FAILURE',
	SAVE_SPORT: 'SAVE_SPORT',
	SAVE_SPORT_SUCCESS: 'SAVE_SPORT_SUCCESS',
	SAVE_SPORT_FAILURE: 'SAVE_SPORT_FAILURE',
	DELETE_SPORT: 'DELETE_SPORT',
	DELETE_SPORT_SUCCESS: 'DELETE_SPORT_SUCCESS',
	DELETE_SPORT_FAILURE: 'DELETE_SPORT_FAILURE',
}

const name = "SportStore";

if (store.state[name]) {
	store.unregisterModule(name);
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store,
})
export default class SportModule extends VuexModule {
	numSports: number = 0;
	sports: SportModel[] = [];
	currentSport: SportModel = undefined;

	loadedSports: boolean = false;
	loadingSports: boolean = false;
	findingSport: boolean = false;
	creatingSport: boolean = false;
	deletingSport: boolean = false;
	@Action async loadSports(): Promise<SportModel[]> {
		if( this.sports.length > 0 ) return;

		this.context.commit(Mutations.QUERY_SPORTS);
		try {
			const options: QueryOptions = {page: 1, limitPerPage: -1}
			const data = await sportApi.queryAll( { fields: [] }, options );
			this.context.commit(Mutations.QUERY_SPORTS_SUCCESS, data);
			return data.docs;
		} catch (e) {
			this.context.commit(Mutations.QUERY_SPORTS_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_SPORTS](): void {
		this.loadingSports = true;
	}
	@Mutation [Mutations.QUERY_SPORTS_SUCCESS](data: PaginatedResponse<SportModel>): void {
		this.loadingSports = false;
		this.numSports = data.total;
		this.sports = data.docs;
		this.loadedSports = true;
	}
	@Mutation [Mutations.QUERY_SPORTS_FAILURE](error: any): void {
		this.loadingSports = false;
	}

	@Action async findSportByName(sportName: string): Promise<SportModel> {
		this.context.commit(Mutations.FINDBYNAME_SPORTS);
		try {
			const data = await sportApi.findByName(sportName);
			this.context.commit(Mutations.FINDBYNAME_SPORTS_SUCCESS, data);
			return data;
		} catch(e) {
			this.context.commit(Mutations.FINDBYNAME_SPORTS_FAILURE, e);
		}
	}

	@Action async findSport(sportId: string): Promise<SportModel> {
		this.context.commit(Mutations.FINDBYID_SPORTS);
		try {
			const data = await sportApi.findById(sportId);
			this.context.commit(Mutations.FINDBYID_SPORTS_SUCCESS, data);
			return data;
		} catch (e) {
			this.context.commit(Mutations.FINDBYID_SPORTS_FAILURE, e);
		}
	}
	@Mutation [Mutations.FINDBYID_SPORTS](): void {
		this.findingSport = true;
	}
	@Mutation [Mutations.FINDBYID_SPORTS_SUCCESS](data: SportModel): void {
		this.currentSport = data;
		this.findingSport = false;
	}
	@Mutation [Mutations.FINDBYID_SPORTS_FAILURE](error: any): void {
		this.findingSport = false;
	}
    
	@Action async saveSport(sport: SportModel): Promise<SportModel> {
		this.context.commit(Mutations.SAVE_SPORT)
		try{
			const newSport = await sportApi.save(sport);
			this.context.commit(Mutations.SAVE_SPORT_SUCCESS);
			return newSport;
		}catch(e){
			this.context.commit(Mutations.SAVE_SPORT_FAILURE, e);
		}
	}
	@Mutation [Mutations.SAVE_SPORT](){
		this.creatingSport = true;
	}
	@Mutation [Mutations.SAVE_SPORT_SUCCESS](){
		this.creatingSport = false;
	}
	@Mutation [Mutations.SAVE_SPORT_FAILURE](){
		this.creatingSport = false;
	}

	@Action async deleteSport(sport: SportModel): Promise<SportModel> {
		this.context.commit(Mutations.DELETE_SPORT)
		try{
			const delSport = await sportApi.delete(sport);
			this.context.commit(Mutations.DELETE_SPORT_SUCCESS);
			return delSport;
		}catch(e){
			this.context.commit(Mutations.DELETE_SPORT_FAILURE, e);
		}
	}
	@Mutation [Mutations.DELETE_SPORT](){
		this.deletingSport = true;
	}
	@Mutation [Mutations.DELETE_SPORT_SUCCESS](){
		this.deletingSport = false;
	}
	@Mutation [Mutations.DELETE_SPORT_FAILURE](){
		this.deletingSport = false;
	}

}