export const LoadingPage = 'LoadingPage';
export const LandingPage = 'LandingPage';
export const RootOnboarding = 'RootOnboarding';
export const EmailVerification = 'EmailVerification';
export const AthleteTeamInvitationDecline = 'AthleteTeamInvitationDecline';
export const NoInvitation = 'NoInvitation';
export const ViewEvaluation = 'ViewEvaluation';
export const StudentViewEvaluation = 'StudentViewEvaluation';
export const DemoDiscussion = 'DemoDiscussion';
export const DemoSSE = 'DemoSSE';
export const DemoImageCache = 'DemoImageCache';
export const DemoComponents = 'DemoComponents';
export const UIComponentDocumentation = 'UIComponentDocumentation';
export const NotFound = 'NotFound';
export const Unauthorized = 'Unauthorized';

export const ProfileManagement = 'ProfileManagement';
export const AthleteProfilesManagement = 'AthleteProfilesManagement';
export const UpgradeSubscriptionPage = 'UpgradeSubscriptionPage';

export const AthletePartner = 'AthletePartner';
export const AthleteBestEducation = 'AthleteBestEducation';

export const PublicPartner = 'PublicPartner';
export const PublicBestEducation = 'PublicBestEducation';
export const AthletePublicPartner = 'AthletePublicPartner';
export const AthletePublicBestEducation = 'AthletePublicBestEducation';

export const AthleteProfileSetup = 'AthleteProfileSetup';
export const AthleteDashboard = 'AthleteDashboard';
export const AthleteRecruitingProfileView = 'AthleteRecruitingProfileView';
export const AthleteSharingSummary = 'AthleteSharingSummary';
export const AthleteRecruitingProfileEdit = 'AthleteRecruitingProfileEdit';
export const AthleteProfileMetric = 'AthleteProfileMetric';
export const AthleteComparativeData = 'AthleteComparativeData';
export const AthleteComparativeDataSummary = 'AthleteComparativeDataSummary';
export const AthleteComparativeDataCompare = 'AthleteComparativeDataCompare';
export const AthleteComparativeDataMyProgress = 'AthleteComparativeDataMyProgress';
export const AthleteComparativeDataPerformanceSummary = 'AthleteComparativeDataPerformanceSummary';
export const AthleteMindsetReport = 'AthleteMindsetReport';
export const AthleteScoutingReportsView = 'AthleteScoutingReportsView';
export const AthleteTeamsSummary = 'AthleteTeamsSummary';
export const AthleteReportsSummary = 'AthleteReportsSummary';
export const AthleteVerifiedAssessmentSummary = 'AthleteVerifiedAssessmentSummary';
export const AthleteSchoolSearch = 'AthleteSchoolSearch';
export const ProOrganizationSearch = 'ProOrganizationSearch';
export const AthleteSelfReportSummary = 'AthleteSelfReportSummary';
export const AthleteSelfAssessmentForm = 'AthleteSelfAssessmentForm';
export const AthleteSelfAssessmentIntro = 'AthleteSelfAssessmentIntro';
export const AthleteScheduleView = 'AthleteScheduleView';

export const AccountSettingsPersonalInfo = 'AccountSettingsPersonalInfo';
export const AccountSettingsProfilePicture = 'AccountSettingsProfilePicture';
export const AccountSettingsChangePassword = 'AccountSettingsChangePassword';
export const AthleteSettings = 'AthleteSettings';
export const AthleteSettingsPaymentForm = 'AthleteSettingsPaymentForm';
export const AthleteSettingsSubscriptionForm = 'AthleteSettingsSubscriptionForm';
export const AthleteSelfReport = 'AthleteSelfReport';
export const AthleteTeamJoin = 'AthleteTeamJoin';
export const AthleteClaimAssessmentForm = 'AthleteClaimAssessmentForm';
export const AthleteClaimSPQAssessmentForm = 'AthleteClaimSPQAssessmentForm';
export const AthleteVideoStudio = 'AthleteVideoStudio';
export const AthleteCheckoutPage = 'AthleteCheckoutPage';
export const AthleteScoutingReport = 'AthleteScoutingReport';
export const AthleteReport = 'AthleteReport';
export const AthleteSchedulePage = 'AthleteSchedulePage';

export const CoachSignup = 'CoachSignup';
export const CoachProfileSetup = 'CoachProfileSetup';
export const CoachDashboard = 'CoachDashboard';
export const CoachScoutingReportForm = 'CoachScoutingReportForm';
export const CoachScoutingReportFormAthlete = 'CoachScoutingReportFormAthlete';
export const CoachAthleteReport = 'CoachAthleteReport';
export const CoachAthleteReportFormAthlete = 'CoachAthleteReportFormAthlete';
export const CoachViewAthlete = 'CoachViewAthlete';
export const CoachViewAthleteVideos = 'CoachViewAthleteVideos';
export const CoachViewTeam = 'CoachViewTeam';
export const CoachTeamProgressDashboard = 'CoachTeamProgressDashboard';
export const TeamDashboard = 'TeamDashboard';
export const TeamDashboardCalendar = 'TeamDashboardCalendar';
export const TeamDashboardRoster = 'TeamDashboardRoster';
export const TeamDashboardDepthCharts = 'TeamDashboardDepthCharts';
export const TeamDashboardGames = 'TeamDashboardGames';
export const AthleteTeamDashboardCalendar = 'AthleteTeamDashboardCalendar';
export const AthleteTeamDashboardRoster = 'AthleteTeamDashboardRoster';
export const AthleteTeamDashboardGames = 'AthleteTeamDashboardGames';
export const FullScreenCalendar = 'FullScreenCalendar';
export const AthleteFullScreenCalendar = 'AthleteFullScreenCalendar';
export const AthleteTeamDashboard = 'AthleteTeamDashboard';
export const AthleteTeamEventView = 'AthleteTeamEventView';
export const TeamEventCreate = 'TeamEventCreate';
export const TeamEventEdit = 'TeamEventEdit';
export const TeamEventView = 'TeamEventView';
export const CoachProfile = 'CoachProfile';
export const TeamCreate = 'TeamCreate';
export const EditTeam = 'EditTeam';
export const ShareTeam = 'ShareTeam';
export const CoachCreateResume = 'CoachCreateResume';
export const CoachEditResume = 'CoachEditResume';
export const CoachCentralScoutingDashboard = 'CoachCentralScoutingDashboard';
export const CoachConnectWithAthletes = 'CoachConnectWithAthletes';
export const CoachGameReportsSummary = 'CoachGameReportsSummary';
export const CoachGameReport = 'CoachGameReport';
export const CoachScoutingSummary = 'CoachScoutingSummary';
export const CoachAthleteReportsSummary = 'CoachAthleteReportsSummary';
export const ViewScoutingReport = 'ViewScoutingReport';
export const AthleteBAEventDetail = 'AthleteBAEventDetail';
export const CoachBAEventDetail = 'CoachBAEventDetail';
export const BAShowcaseEvent = 'BAShowcaseEvent';

export const InstructorCourseList = 'InstructorCourseList';
export const CourseDashboard = 'CourseDashboard';
export const EditCourse = 'EditCourse';
export const NewAssignment = 'NewAssignment';
export const CourseCreate = 'CourseCreate';
export const AssignmentEvaluation = 'AssignmentEvaluation';

export const StudentDashboard = 'StudentDashboard';
export const SimplePageLayout = 'SimplePageLayout';
export const StudentCoursePage = 'StudentCoursePage';
export const AssignmentSubmission = 'AssignmentSubmission';

export const EventDetails = 'EventDetails';
export const RegisterForAssessment = 'RegisterForAssessment';
export const SchoolDetails = 'SchoolDetails';
export const BASchoolDetails = 'BASchoolDetails';

export const ProOrganizationDetails = 'ProOrganizationDetails';

export const PublicDashboard = 'PublicDashboard';
export const PublicViewAthlete = 'PublicViewAthlete';
export const PublicViewTeam = 'PublicViewTeam';

export const AthletePublicEventDetails = 'AthletePublicEventDetails';
export const AthletePublicDashboard = 'AthletePublicDashboard';