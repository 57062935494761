
import { BaseSoccerPosition, SoccerPosition, DominantSide } from '@/../types/enums';

export function capitalizedInitials(item: any): string {
    if( !item ) return '-';

    const itemStr = String(item);
    return itemStr.charAt(0).toUpperCase();
}


export function formatPositionShort(pos: BaseSoccerPosition | SoccerPosition | string): string {
    if( !pos ) return '--';

    if( typeof(pos) === "string") {
        if( pos.toLowerCase().startsWith('g') ) return "GK";
        if( pos.toLowerCase().startsWith('d') ) return "DF";
        if( pos.toLowerCase().startsWith('m') ) return "MF";
        if( pos.toLowerCase().startsWith('f') ) return "FW";
        return '--';
    }

    if( pos == BaseSoccerPosition.Goalkeeper ) return "GK";
    if( pos == BaseSoccerPosition.Defender ) return "DF";
    if( pos == BaseSoccerPosition.Midfielder ) return "MF";
    if( pos == BaseSoccerPosition.Forward ) return "FW";

    if( pos == SoccerPosition.Goalkeeper ) return "GK";
	if( pos == SoccerPosition.RightFullback ) return "DF";
	if( pos == SoccerPosition.LeftFullback ) return "DF";
	if( pos == SoccerPosition.CenterBack ) return "DF";
	if( pos == SoccerPosition.DefendingMidfielder ) return "MF";
	if( pos == SoccerPosition.RightMidfielder ) return "MF";
	if( pos == SoccerPosition.CentralMidfielder ) return "MF";
	if( pos == SoccerPosition.AttackingMidfielder ) return "MF";
	if( pos == SoccerPosition.LeftMidfielder ) return "MF";
	if( pos == SoccerPosition.Striker ) return "FW";

    return capitalizedInitials(pos);
}

export function formatDominantShort(side: DominantSide): string {
    if( !side || side.length === 0 ) return '--';

    if( side == DominantSide.Left ) return "L";
    if( side == DominantSide.Right ) return "R";
    if( side == DominantSide.Ambidextrous ) return "B";

    return capitalizedInitials(side);
}