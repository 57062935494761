
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, SportsSelectorMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import { PlayerOnTeam, TeamModel } from "@/models/team";
import { baIcons } from '@/../types/constants/baIcons';
import { teamApi } from '@/api/TeamApi';

@Component({
    components: {}
})
export default class AthleteEditInfoDialog extends Mixins(StringsMixin, VuetifyMixin, BAIconsMixin, SportsSelectorMixin) {
    @Prop({ required: true, default: undefined }) value: PlayerOnTeam;
    @Prop({ required: true, default: undefined }) team: TeamModel;
    @Prop({ default: "Edit Player Information"}) tooltip;
    @Prop({ default: "Not Available" }) disabledTooltip;
    @Prop({ default: baIcons.edit}) icon;
    @Prop({ default: "" }) class: string;
    @Prop({ type: Boolean }) disabled;
    @Prop({ type: Boolean }) noIcon;
    @Prop({ type: Boolean }) large;
    @Prop({ type: Boolean }) dense;
    @Prop() text: string;

    @Prop({ type: Boolean }) gradYear;
    @Prop({ type: Boolean }) commitment;
    @Prop({ type: Boolean }) health;
    @Prop({ type: Boolean }) availability;

	editingPlayer: boolean = false;
	editPlayer: PlayerOnTeam;
	onStartEditPlayer() {
        // make a copy of the player to edit
		this.editPlayer = {...this.value};
		this.editingPlayer = true;
	}
	onCancelEditPlayer() {
        // player has not been changed, reset state
        this.editPlayer = undefined;
		this.editingPlayer = false;
        this.$emit("cancel");
	}
	async onAcceptEditPlayer(teamId: string) {
        // copy the contents of the edited player onto the original player
        for( const key in this.editPlayer ) {
            if( this.editPlayer.hasOwnProperty(key) ) {
                this.value[key] = this.editPlayer[key];
            }
        }

        // update the player information to persistent storage
		await this.patchPlayer(this.value.id, teamId, this.value);
        this.$emit("input", this.value);

        // reset state
		this.editingPlayer = false;
        this.editPlayer = undefined;
	}

    updatingStatus = false;
    onSetInjuryStatus() {
        this.updatingStatus = true;
        this.editPlayer.injured = !this.editPlayer.injured;
        this.updatingStatus = false;
    }
    onSetEligibilityStatus() {
        this.updatingStatus = true;
        this.editPlayer.eligibleToPlay = !this.editPlayer.eligibleToPlay;
        this.updatingStatus = false;
    }

    async patchPlayer(playerId: string, teamId: string, playerPatch: Partial<PlayerOnTeam>): Promise<PlayerOnTeam> {
        return await teamApi.patchPlayer( {teamId, playerId, playerPatch });
	}
}

