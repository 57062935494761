/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Route, RouteConfig } from 'vue-router';
import { coachAuthGuard } from './routeGuards/coachAuthGuard';
import { CoachSideNavLayout } from '../layouts';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { HinderRouteMetadata } from './router';
import { SettingsChildRouteConfigs } from './SettingsChildRouteConfigs';
import { StudentRouteConfigs } from './StudentRouteConfigs';
import { InstructorRouteConfigs } from './InstructorRouteConfigs';

const CoachDashboard = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachDashboard.vue');
const TeamDashboardView = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamDashboardView.vue');
const FullScreenCalendar = () => import(/* webpackChunkName: "CoachModule" */ '@/views/FullScreenCalendar.vue');
const CoachProfile = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachProfile.vue');
const TeamCreate = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamCreate.vue');
const TeamShare = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamShare.vue');
const TeamScout = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamScout.vue');
const TeamEventCreate = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamEventCreate.vue');
const TeamEventView = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamEventView.vue');
const CoachCreateResume = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachCreateResume.vue');
const AthleteRecruitingProfileView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteRecruitingProfileView.vue');
const AthleteRecruitingProfileVideosView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteRecruitingProfileVideosView.vue');
const CoachCentralScoutingDashboard = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachCentralScoutingDashboard.vue');
const CoachConnectWithAthletes = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachConnectWithAthletes.vue');
const CoachGameReportsSummary = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachGameReportsSummary.vue')
const CoachGameReport = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachGameReport.vue')
const CoachAthleteReportsSummary = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachAthleteReportsSummary.vue');
const CoachScoutingSummary = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachScoutingSummary.vue');
const CoachScoutingReportForm = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachScoutingReportForm.vue');
const ViewScoutingReport = () => import(/* webpackChunkName: "CoachModule" */ '@/views/ViewScoutingReport.vue');
const CoachAthleteReport = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachAthleteReport.vue');
const CalendarV2 = () => import(/* webpackChunkName: "CoachModule" */ '@/components/calendar/CalendarV2.vue');
const DepthChartV2 = () => import(/* webpackChunkName: "CoachModule" */ '@/components/teams/DepthChart/DepthChartV2.vue');
const TeamRosterV2 = () => import(/* webpackChunkName: "CoachModule" */ '@/components/teams/TeamRosterV2.vue');
const TeamGamesProvider = () => import(/* webpackChunkName: "CoachModule" */ '@/components/teams/TeamGamesProvider.vue');
const BAEventDetailPage = () => import(/* webpackChunkName: "CoachModule" */ '@/views/BAEventDetailPage.vue');
const BAShowcaseEventPage = () => import(/* webpackChunkName: "CoachModule" */ '@/views/BAShowcaseEventPage.vue');

export const CoachRouteConfigs: RouteConfig[] = [
	{
		beforeEnter: coachAuthGuard,
		path: '/c',
		component: CoachSideNavLayout,
		props: true,
		children: [
			{
				path: '',
				name: Routes.CoachDashboard,
				component: CoachDashboard,
				meta:<HinderRouteMetadata>{
					pageTitle: 'Coach Dashboard'
				}
			},
			{
				path: 'team/dashboard/:teamId',
				name: Routes.TeamDashboard,
				component: TeamDashboardView,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Team Dashboard',
				},
				children:[
					{
						path: 'roster',
						name: Routes.TeamDashboardRoster,
						component: TeamRosterV2,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
						props: {
							coach: true,
						}
					},
					{
						path: 'depth-chart',
						name: Routes.TeamDashboardDepthCharts,
						component: DepthChartV2,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
					},
					{
						path: 'calendar',
						name: Routes.TeamDashboardCalendar,
						component: CalendarV2,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
					},
					{
						path: 'games',
						name: Routes.TeamDashboardGames,
						component: TeamGamesProvider,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
					}
				]
			},
			{
				path: 'team/dashboard/:teamId/calendar/fullscreen',
				name: Routes.FullScreenCalendar,
				component: FullScreenCalendar,
				props: true,
				meta: <HinderRouteMetadata>{
					hideAppBar: true,
					showOnMobile: true,
				}
			},
			{
				path: 'team/dashboard/:teamId/event/create',
				name: Routes.TeamEventCreate,
				component: TeamEventCreate,
				props: true,
				meta:{
					hideAppBar: true,
				}
			},
			{
				path: 'team/dashboard/:teamId/event/:teamEventId/edit',
				name: Routes.TeamEventEdit,
				component: TeamEventCreate,
				props: true,
				meta:{
					hideAppBar: true,
				}
			},
			{
				path: 'team/dashboard/:teamId/event/:teamEventId/view',
				name: Routes.TeamEventView,
				component: TeamEventView,
				props: true,
				meta:{
					hideAppBar: true,
				}
			},
			{
				path: 'coachprofile/:coachId',
				name: Routes.CoachProfile,
				component: CoachProfile,
				props: true,
			},
			{
				path: 'baevent/:eventId',
				name: Routes.CoachBAEventDetail,
				component: BAEventDetailPage
			},
			{
				path: 'bashowcase/:eventId',
				name: Routes.BAShowcaseEvent,
				component: BAShowcaseEventPage,
				meta: {
					pageTitle: "Best Athletes Showcase Event"
				}
			},
			{
				name: Routes.CoachViewAthlete,
				path: 'athlete/:athleteId/:sharingUrlId?',
				component: AthleteRecruitingProfileView,
				props: true,
				meta: {
					pageTitle: "Athlete Recruiting Profile"
				}
			},
			{
				name: Routes.CoachViewTeam,
				path: 'teams/:teamId/:sharingUrlId?',
				component: TeamShare,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: 'Team Athlete Profiles',
				},
			},
			{
				path: 'athlete/:athleteId/videos/:sharingUrlId?',
				name: Routes.CoachViewAthleteVideos,
				component: AthleteRecruitingProfileVideosView,
				props: (to: Route) => {
					return {
						...to.params,
						...to.query,
					};
				},
				meta: {
					pageTitle: "All Videos"
				}
			},
			{
				path: 'create/teams/:currentStep?',
				name: Routes.TeamCreate,
				component: TeamCreate,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Create Team',
				}
			},
			{
				name: Routes.ShareTeam,
				path: 'teams/:teamId/share',
				component: TeamShare,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: 'Share Team',
				},
			},
			{
				path: 'edit/teams/:teamId/:currentStep?',
				name: Routes.EditTeam,
				component: TeamCreate,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Edit Team',
				},
				props: (to: Route) => {
					return {
						editTeamId: to.params['teamId'],
						selfRoleOptional: false
					}
				},
			},
			{
				path: 'createResume/:currentStep?/edit',
				name: Routes.CoachEditResume,
				component: CoachCreateResume,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Edit Profile"
				}
			},
			{
				path: 'createResume/:currentStep?',
				name: Routes.CoachCreateResume,
				component: CoachCreateResume,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Setup Profile"
				}
			},
			{
				path: 'signup',
				name: Routes.CoachSignup,
				component: CoachDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Coach Signup"
				}
			},
			{
				path: 'centralscouting',
				name: Routes.CoachCentralScoutingDashboard,
				component: CoachCentralScoutingDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Central Scouting Database"
				}
			},
			{
				path: 'connect',
				name: Routes.CoachConnectWithAthletes,
				component: CoachConnectWithAthletes,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: "Recruiting"
				}
			},
			{
				path: 'gamereports',
				name: Routes.CoachGameReportsSummary,
				component: CoachGameReportsSummary,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: 'Game Reports',
				},
			},
			{
				path: 'gamereport/:reportId?',
				name: Routes.CoachGameReport,
				component: CoachGameReport,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: 'Game Report',
				},
			},
			{
				path: 'evaluations',
				name: Routes.CoachAthleteReportsSummary,
				component: CoachAthleteReportsSummary,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Athlete Reports"
				}
			},
			{
				path: 'evaluations/edit/:reportId?',
				name: Routes.CoachAthleteReport,
				component: CoachAthleteReport,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Athlete Report"
				}
			},
			{
				path: 'scouting',
				name: Routes.CoachScoutingSummary,
				component: CoachScoutingSummary,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Scouting Reports"
				}
			},
			{
				path: 'scoutingform/edit/:reportId?',
				name: Routes.CoachScoutingReportForm,
				component: CoachScoutingReportForm,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Scouting Report"
				}
			},
			{
				path: 'scouting/athlete/:athleteId?/team/:teamId?',
				name: Routes.CoachAthleteReportFormAthlete,
				component: CoachAthleteReport,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "New Athlete Report"
				}
			},
			{
				path: 'scoutingform/athlete/:athleteId?/team/:teamId?',
				name: Routes.CoachScoutingReportFormAthlete,
				component: CoachScoutingReportForm,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Scouting Report for Athlete"
				}
			},
			{
				path: 'scouting/view/:reportId?',
				name: Routes.ViewScoutingReport,
				component: ViewScoutingReport,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Scouting Report"
				}
			},
			...SettingsChildRouteConfigs,
		],
	},
	...InstructorRouteConfigs,
	...StudentRouteConfigs
];